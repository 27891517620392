<template>
  <div class="">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label>Name<sup>*</sup> </label>
        <div class="form-group__content">
          <input class="form-control" type="text" v-model="form.name" placeholder="" />
          <small v-if="errors && errors.name" class="phone_error text-danger">
            {{ errors.name[0] }}
          </small>
        </div>
      </div>
      <div class="form-group">
        <label>Description<sup>*</sup> </label>
        <div class="form-group__content">
          <textarea class="form-control" v-model="form.description"></textarea>
          <small v-if="errors && errors.description" class="text-danger">
            {{ errors.description[0] }}
          </small>
        </div>
      </div>
      <div class="form-group">
        <label>Status<sup>*</sup> </label>
        <div class="form-group__content">
          <select class="form-control" v-model="form.status">
            <option value="1">Pending</option>
            <option value="2">Active</option>
          </select>
          <small v-if="errors && errors.status" class="text-danger">
            {{ errors.status[0] }}
          </small>
        </div>
      </div>
      <div class="form-group">
        <button class="ps-btn" @click.prevent="onSubmit()">
          {{ isLoading ? "Submitting..." : "Submit" }}
          <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
          <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RunnerCategoryForm",
  props: {
    runnerCategoryId: Number
  },
  data() {
    return {
      runnerCategory: null,
      form: {
        name: '',
        description: '',
        status: '',
      },
      show: true,
      isError: false,
      isLoading: false,
      errors: [],
      message: ''
    }
  },
  async created() {
    if (this.runnerCategoryId) {
      axios.get('runners/categories').then((response) => {
        this.categories = response.data;
      });
    }

    if (this.runnerId) {
      axios.get('runners/categories/' + this.runnerCategoryId).then((response) => {
        this.runnerCategory = response.data;
        this.form.description = this.runnerCategory.description;
        this.form.name = this.runner.name;
        this.form.status = this.runner.status;
      });
    }
  },
  methods: {
    async onSubmit() {
      if (this.runnerCategoryId) {
        await this.updateRunnerCategory()
      } else {
        await this.createRunnerCategory()
      }
    },

    createRunnerCategory() {
      this.isLoading = true;
      axios.post('runners/categories', this.form).then((response) => {
        this.isLoading = false;
        this.handleSuccess(response, 'Runner category saved successfully.');
      }).catch((error) => {
        this.isLoading = false;
        this.handleErrors(error, 'Runner category could not be created. Please enter all required fields.')
      });
    },

    updateRunnerCategory() {
      this.isLoading = true;
      axios.put('runners/categories/' + this.runnerCategoryId, this.form).then((response) => {
        this.isLoading = false;
        this.handleSuccess(response, 'Runner category updated successfully.');
      }).catch((error) => {
        let message = '';
        this.isLoading = false;
        this.handleErrors(error, 'Runner category could not be updated. Please enter all required fields.')
      });
    }
  },

  handleSuccess(message = null) {
    if (message) {
      this.$toasted.success(message);
    }
    this.isLoading = false;
    setTimeout(() => {
      this.$router.push({ name: "admin_runners_categories" });
    }, 1000);
  },

  handleErrors(error, message) {
    if (error.response.status === 422) {
      if (message) {
        this.$toasted.error(message);
      }
      this.errors = error.response.data.errors
    } else {
      this.message = error.backendErrors[0]
    }
    this.isError = true
    this.isLoading = false;
  }
}
</script>

<style scoped>

</style>